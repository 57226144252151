import React from 'react';
import {
  GithubIcon,
  GmailIcon,
  LinkedInIcon,
  TwitterIcon,
} from '../../Globals/Icons';
import * as Styled from './styled';

interface AboutSectionProps {
  id: string;
}

export const AboutSection: React.FC<AboutSectionProps> = (props) => {
  return (
    <div id={props.id}>
      <h2># About</h2>
      <Styled.AboutSection>
        <Styled.AboutItem style={{ textAlign: 'center' }}>
          <div>
            <img
              style={{
                borderRadius: '50%',
                width: '200px',
                border: '3px solid var(--accent-color-mint)',
              }}
              src="https://tycorcoran-assets.s3.us-east-2.amazonaws.com/img/tycorcoran.png"
              alt="Ty Corcoran"
            />
            <h4>Power Platform Architect</h4>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <GithubIcon
                width={50}
                color="#FFF"
                link="https://github.com/tcorcor1"
              />
              <LinkedInIcon
                width={50}
                color="#FFF"
                link="https://www.linkedin.com/in/ty-corcoran-a62a8221/"
              />
              <TwitterIcon
                width={50}
                color="#FFF"
                link="https://twitter.com/TyCorcoran5"
              />
              <GmailIcon
                width={50}
                color="#FFF"
                link="mailto:tldr.dynamics@gmail.com"
              />
            </div>
          </div>
        </Styled.AboutItem>

        <Styled.AboutItem style={{ textAlign: 'left' }}>
          <p>
            I am currently a Power Platform solution architect with a passion
            for development. I have been building solutions in Dynamics since
            CRM 2011.
          </p>
          <p>
            In my free time I enjoy making open-source tools for the Power
            Platform. Typically they will leverage either React, Vue or C# and I
            try to make them as configuration-free as possible. I document my
            tools and share various discoveries and lessons learned on my blog{' '}
            <a href="https://tldr-dynamics.com">tldr-dynamics.com</a>.
          </p>
          <p>
            Below you will find the aforementioned tools/templates. I hope you
            find something here that makes your work with the Power Platform a
            little bit easier.
          </p>
        </Styled.AboutItem>
      </Styled.AboutSection>
    </div>
  );
};
